export default {
    type: 'Table',
    filter_keys: [
        ['Filter', 'noslo']
    ],
    other_options: {
        remove_rows: [
            {
                country_code: 'RO',
                source_name: 'DC'
            }
        ]
    }
}
